import Table from '@components/Table';
import Badge from '@components/Badge';
import { Card } from '@components/Card';
import Indicator from '@components/Indicator';
import { SectionTitle } from '@components/SectionTitle';
import { getBadgeStatus } from '@components/Badge/status';
import FeaturesIcon from '@assets/icons/Features';
import { BadgeStatus } from '@constants/badge';
import Info from '@assets/icons/info';

import { FormatedDataItem, FeaturesProps, PropertyData } from './Features.types';

export const Features = ({
  comparisonKeys,
  significances,
  propertyData,
  features,
}: FeaturesProps) => {
  const columns = [
    {
      sortable: true,
      label: 'Feature Name',
      accessor: 'featureName',
      widthClass: 'w-1/4',
    },
    {
      sortable: true,
      label: 'Significance',
      accessor: 'significance',
      widthClass: 'w-1/4',
    },
    {
      sortable: true,
      label: 'Value',
      accessor: 'value',
      widthClass: 'w-1/4',
    },
    {
      sortable: false,
      label: 'Valence',
      accessor: 'valence',
      widthClass: 'w-1/4',
    },
  ];

  const getFeature = (featureName: string) => {
    const feature = features.filter((item) => item.Feature === featureName)[0];

    return feature;
  };

  const getUnit = (featureName: string) => {
    const feature = getFeature(featureName);
    const featureUnit = feature?.Units;

    return featureUnit ==='None' ? '' : featureUnit;
  };

  const getSignificanceStatus = (significanceValue: number) => {
    const significanceMap = {
      1: 'Low',
      2: 'Medium',
      3: 'High',
    };

    return significanceMap[significanceValue as keyof typeof significanceMap];
  };

  const formatData = (
    comparisonKeys: string[],
    significances: number[],
    propertyData: PropertyData,
  ) => {
    const formattedData: FormatedDataItem[] = comparisonKeys.map((key: string, index: number) => {
      const featureName = key;
      const featureUnit = getUnit(featureName as string);
      console.log(featureName, featureUnit);
      const significance = significances[index];
      const value =
        key === 'U.S. State'
          ? propertyData[`U`]['S'][' state']
          : `${propertyData[featureName]}${featureUnit ? ' ' + featureUnit : ''}`;
      const valence =
        key === 'U.S. State'
          ? propertyData[`U`]['S'][' State_valence']
          : propertyData[`${featureName}_valence`];

      const rowData = {
        featureName: featureName,
        significance: <Indicator status={getSignificanceStatus(significance) as any} />,
        value: value,
        valence: (
          <Badge
            label={valence as string}
            muted
            size="sm"
            variant={getBadgeStatus(valence as BadgeStatus)}
            icon={<Info />}
          />
        ),
      };

      return rowData;
    });

    return formattedData;
  };

  return (
    <section className="flex flex-col gap-4">
      <SectionTitle title="Features" icon={<FeaturesIcon />} />
      <Card>
        <Table
          data={formatData(comparisonKeys, significances, propertyData)}
          columns={columns}
          initialSortField={'significance'}
        />
      </Card>
    </section>
  );
};
